import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of, tap } from "rxjs";
import { AuthService } from "src/app/auth/services/auth.service";
import { UserWithRoles } from "src/app/models/user-access";
import { GetUserWithRoles, UpdateUserRoles } from "./user-access.action";

type UserWithRolesStateModel = UserWithRoles[];

@State<UserWithRolesStateModel>({
    name: 'UserRolesState',
    defaults: [],
})
@Injectable()
export class UserAccessState {

    constructor(private auth: AuthService) { }

    @Selector()
    static getUsersWithRoles(state: UserWithRolesStateModel) {
        return state;
    }

    @Action(GetUserWithRoles)
    fetchUsersWithRoles(ctx: StateContext<UserWithRolesStateModel>) {

        if (ctx.getState().length) {
            return of(ctx.getState());
        }

        return this.auth.getUsersWithRoles().pipe(tap(userWithRoles => ctx.setState(userWithRoles)));
    }

    @Action(UpdateUserRoles)
    updateUserRoles(ctx: StateContext<UserWithRolesStateModel>, action: UpdateUserRoles) {
        const state = ctx.getState();
        const userWithRoles = action.payload;

        this.auth.updateUserRoles(userWithRoles).subscribe(() => {
            const userIndex = state.findIndex(user => user.email === userWithRoles.email);

            if (userIndex !== -1) {
                const updatedState = [...state];
                updatedState[userIndex] = { ...updatedState[userIndex], roles: userWithRoles.roles };

                return ctx.setState(updatedState);
            }
            return state;
        });

    }

}