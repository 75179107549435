import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CustomerJorneyTemplate } from "../models/notifications/customer-jorney";
import { CustomerJourneyStep } from "../models/notifications/customer-journey-step.model";

@Injectable({ providedIn: 'root' })
export class CustomerJourneyService {
    private http = inject(HttpClient);

    private readonly url: string = `${environment.api.url}customer-journey`;

    public getCustomerJourneys(): Observable<CustomerJorneyTemplate[]> {
        return this.http.get<CustomerJorneyTemplate[]>(this.url);
    }

    public getCustomerJourney(id: number): Observable<CustomerJorneyTemplate> {
        return this.http.get<CustomerJorneyTemplate>(`${this.url}/${id}`);
    }

    public addCustomerJourney(cJourney: CustomerJorneyTemplate): Observable<CustomerJorneyTemplate> {
        return this.http.post<CustomerJorneyTemplate>(`${this.url}`, cJourney);
    }

    public updateCustomerJourney(cJourney: CustomerJorneyTemplate): Observable<CustomerJorneyTemplate> {
        return this.http.put<CustomerJorneyTemplate>(this.url, cJourney);
    }

    public deleteCustomerJourney(id: number) {
        return this.http.delete(`${this.url}/${id}`);
    }

    public approve(id: number): Observable<CustomerJorneyTemplate> {
        return this.http.post<CustomerJorneyTemplate>(`${this.url}/${id}/approve`, {});
    }

    public getAiTemplatesForCustomerJourney(prompt: string, count: number): Observable<any> {
        return this.http.post<any>(`${this.url}/aiprompt/${count}`, { prompt });
    }

    //customer journey steps apis

    public sendStepEmail(userEmail: string, subject: string, body: string, image: string) {
        const data = {
            subject: subject,
            body: body,
            userEmail: userEmail,
            image: image
        }
        return this.http.post(`${this.url}/send`, data);
    }

    public approveStep(id: number): Observable<CustomerJorneyTemplate> {
        return this.http.post<CustomerJorneyTemplate>(`${this.url}/step/${id}/approve`, {});
    }

    public deleteStep(id: number) {
        return this.http.delete(`${this.url}/step/${id}`);
    }

    public updateCustomerJourneyStep(cJourneyStep: CustomerJourneyStep): Observable<CustomerJourneyStep> {
        return this.http.put<CustomerJourneyStep>(`${this.url}/step/${cJourneyStep.id}`, cJourneyStep);
    }
}
